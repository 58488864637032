import React from 'react';
import './App.css';
import { HashRouter,Route,Switch } from 'react-router-dom'
import './static/config'
import 'antd/dist/antd.css';
import loadable from "./static/util/loadbale";
import LuYou from './pages/components/luyou/index'
import RightPage from './pages'

const Login = loadable(()=>import('./pages/login'));
const Login2 = loadable(()=>import('./pages/components/login2'));
const ForgetPassword = loadable(()=>import('./pages/forgetPassword'));
const WeChatPage = loadable(()=>import('./pages/weChat'));
const PolicePage = loadable(()=>import('./pages/components/luyou'))
const Exhibition = loadable(()=>import('./pages/exhibition'));

//exact是精确匹配
function App() {
  return (
      <HashRouter>
        <div>
            <Switch>
                <Route exact strict path="/" component={Login} />
                <Route exact strict path="/manage" component={Login2} />
                <Route strict path="/forgetPassword" component={ForgetPassword}/>
                <Route strict path="/sys" component={RightPage}/>
                <Route strict path="/weChat" component={WeChatPage} />
                <Route strict path="/police" component={LuYou}/>
                <Route strict path="/pages" component={PolicePage} />
                <Route exact path="/exhibition" component={Exhibition} />
                <Route path="/exhibition/:exhibitionTime" component={Exhibition} />
            </Switch>
        </div>
      </HashRouter>
  );
}

export default App;
