import React from 'react'
import { HashRouter,Route,Link} from 'react-router-dom'
import { Layout, Menu,} from 'antd';
import '../../../static/config'
import {fetchPost} from "../../../static/util/fetch";
import loadable from "../../../static/util/loadbale";
import {createHashHistory} from "history";
const Overall1 = loadable(()=>import('../overall/overall1'));
const CarOverall = loadable(()=>import('../caroverall/carOverall'));
const DriverOverall = loadable(()=>import('../driveroverall/driverOverall'));
const DutyOverall = loadable(()=>import('../dutyoverall/dutyOverall'));
const SaferOverall = loadable(()=>import('../saferoverall/saferOverall'));
const TrainOverall = loadable(()=>import('../trainOverall/trainOverall'));
const ExamOverall = loadable(()=>import('../examOverall/examOverall'));
const ConferenceOverall = loadable(()=>import('../conferenceOverall/conferenceOverall'));
const InspectionOverall = loadable(()=>import('../inspectionOverall/inspectionOverall'));
const CargoOverall = loadable(()=>import('../cargoOverall/CargoOverall'));
const MaintainOverall = loadable(()=>import('../maintainOverall/maintainOverall'));
const Exhibition = loadable(()=>import('../exhibition'));
const Supervise = loadable(()=>import('../jiancha/index'));

const { Header, Content } = Layout;

class LuYou extends React.Component {
    state={

            }
        /* this.selectx = this.selectx.bind(this)
         this.state = {
             inputValue: '',*/
    handleLogout = () => {
        let params = {}
        fetchPost(global.constants.logout, params)
            .then(
                res => createHashHistory().push('/manage')
            )
    }

componentDidMount() {
    let params = {}
    console.log(777)
    fetchPost(global.constants.getAdminType2, params)
        .then(
            res => {
                this.setState({
                    userType: res
                })
            }
        )
        .catch(e => console.log(e))


.catch(e => console.log(e))

}

    render() {
        const{ userType }=this.state
        return <HashRouter>
            <Layout className="layout" style={{height: '100%'}}>
                <Header>
                    {console.log(4444)}
                    <div/>
                    <Menu
                        theme="dark"
                        mode="horizontal"
                        // defaultSelectedKeys={[window.location.hash.split('/')[1]]}
                        style={{lineHeight: '64px'}}
                    >
                        <Menu.Item key="overall1"><Link to="/police/overall1">公司总览</Link></Menu.Item>
                        <Menu.Item key="carOverall"><Link to="/police/carOverall">车辆总览</Link></Menu.Item>
                        <Menu.Item key="driverOverall"><Link to="/police/driverOverall">驾驶员总览</Link></Menu.Item>
                        <Menu.Item key="trainOverall"><Link to="/police/trainOverall">培训总览</Link></Menu.Item>
                        <Menu.Item key="examOverall"><Link to="/police/examOverall">考试总览</Link></Menu.Item>
                        <Menu.Item key="conferenceOverall"><Link to="/police/conferenceOverall">会议总览</Link></Menu.Item>
                        <Menu.Item key="dutyOverall"><Link to="/police/dutyOverall">负责人</Link></Menu.Item>
                        <Menu.Item key="saferOverall"><Link to="/police/saferOverall">安全员</Link></Menu.Item>
                        <Menu.Item key="inspectionOverall"><Link to="/police/inspectionOverall">临检总览</Link></Menu.Item>
                        <Menu.Item key="CargoOverall"><Link to="/police/CargoOverall">行车日志总览</Link></Menu.Item>
                        <Menu.Item key="maintainOverall"><Link to="/police/maintainOverall">维护总览</Link></Menu.Item>
                        {userType===2?<Menu.Item key="Super"><Link to="/police/supervise">监管管理</Link></Menu.Item>:''}
                        <Menu.Item key="Overall"><Link to="/police/exhibition">情况总览</Link></Menu.Item>
                        <Menu.Item key="none"></Menu.Item>
                        <Menu.Item key="close" onClick={this.handleLogout}>注销</Menu.Item>
                    </Menu>
                </Header>
                <Content style={{
                    backgroundColor: '#fff',
                    minHeight: 'calc(100% - 132px)'
                }}>
                    <Route path="/police/overall1" component={Overall1}/>
                    {console.log(456)}
                    <Route strict path="/police/carOverall" component={CarOverall}/>
                    <Route strict path="/police/driverOverall" component={DriverOverall}/>
                    <Route strict path="/police/dutyOverall" component={DutyOverall}/>
                    <Route strict path="/police/saferOverall" component={SaferOverall}/>
                    <Route strict path="/police/trainOverall" component={TrainOverall}/>
                    <Route strict path="/police/examOverall" component={ExamOverall}/>
                    <Route strict path="/police/conferenceOverall" component={ConferenceOverall}/>
                    <Route strict path="/police/inspectionOverall" component={InspectionOverall}/>
                    <Route strict path="/police/CargoOverall" component={CargoOverall}/>
                    <Route strict path="/police/maintainOverall" component={MaintainOverall}/>
                    <Route exact path="/police/supervise" component={Supervise} />
                    <Route exact path="/police/exhibition" component={Exhibition} />
                    <Route path="/police/exhibition/:exhibitionTime" component={Exhibition} />

                </Content>

            </Layout>,
        </HashRouter>

    }

    // inputChange() {
    //     //console.log(e.target.value)//打印值
    //     this.setState({
    //         //inputValue:e.target.value//input的值为框中的值，即在框中修改时，框里的值发生变化
    //         inputValue: this.input.value//使用ref简化了语义
    //     })
    // }

    // selectx() {
    //     fetch({
    //         method: 'post',
    //         // url: 'http://rap2api.taobao.org/app/mock/235069/index/test',
    //         url: 'http://172.24.181.220:8444/web/select/businessSelect' +
    //         'http://172.24.181.220:8444/web/select/driverSelect'+
    //         'http://172.24.181.220:8444/web/select/vehicleSelect' +
    //             'http://172.24.181.220:8444/web/select/chargeSelect' +
    //             'http://172.24.181.220:8444/web/select/safeSelect' ,
    //         data: {
    //             select: this.input.value
    //         }
    //     }).then(
    //         (res) => {
    //             console.log('fetch获取数据成功：' + JSON.stringify(res))
    //         }
    //     ).catch(
    //         (error) => {
    //             console.log('fetch获取数据失败' + error)
    //         }
    //     )
    //
    //
    // }
    // handle(){const w=window.open('about:blank');
    //     w.location.href='http://localhost:3000/#/'}
}

export default LuYou
