//const serverUrl = 'http://localhost:8444'
//const serverUrl = "https://www.buyersinworld.com:8444"
const serverUrl = "https://www.intell-traffic.com:8444"
//const picUrl = "http://www.buyersinworld.com:8080/driverTrain"
const picUrl = "https://www.intell-traffic.com/source"
global.constants = {
    picUrl: picUrl,
    emptyRequest: serverUrl + '/web/car/empty',//图片上传空接口返回图片路径
    getAdminType: serverUrl + '/web/getAdminType',//获取当前用户身份
    vehicleLicense: serverUrl + '/web/car/vehicleLicense',//行驶证识别
    driverLicense: serverUrl + '/web/driver/driverLicense',//驾驶证识别
    idCard: serverUrl + '/web/idCard',//身份证识别
    login: serverUrl + '/web/login',//登陆接口
    logout: serverUrl + '/web/logout ',//注销接口
    businessGetAll: serverUrl + '/web/user/getAllPaidBusiness',//获取所有企业信息
    anyBusinessGetAll: serverUrl + '/weChat/getBusinessPaid',//获取所有企业信息
    sendPhoneMsg: serverUrl + '/weChat/common/sendPhoneMsg',//向手机号发送验证码
    codeMaching: serverUrl + '/weChat/common/codeMaching',//验证码匹配
    businessGetInfo: serverUrl + '/web/business/getBusiness',//获取单个企业信息
    chargeGetInfo: serverUrl + '/web/roleCharge/getRoleCharge',//获取企业下负责人信息
    chargeUpdate: serverUrl + '/web/roleCharge/update',//更新企业下复负责人信息
    chargeGetAll: serverUrl + '/web/roleCharge/getUnAuthentRoleCharges',//获取企业下所有负责人信息
    safeGetInfo: serverUrl + '/web/roleSafe/getRoleSafe',//获取企业下安全员信息
    safeUpdate: serverUrl + '/web/roleSafe/update',//更新企业下安全员信息
    safeGetAll: serverUrl + '/web/roleSafe/getUnAuthentRoleSafes',//获取企业下所有安全员信息
    basicChangeState: serverUrl + '/web/roleSafe/changeState',//修改责任人，安全员状态
    //updateBasicUser: serverUrl + '/web/basicUser/updateBasicUser',//修改账号密码
    //checkTelephone: serverUrl + '/web/basicUser/checkTelephone',//检查手机号是否可添加
    businessAdd: serverUrl + '/web/user/addBusiness',//新增企业(超级管理员)
    businessUpdate: serverUrl + '/web/user/update',//修改企业(超级管理员)
    changePassword: serverUrl + '/web/user/changePassword',//修改企业密码(超级管理员)
    lowBusinessUpdate: serverUrl + '/web/business/updateBusiness',//修改企业(企业管理员)
    getAdminByBusinessId: serverUrl + '/web/businessAdmin/getAdminByBusinessId',//获取企业的账户信息(企业管理员)
    changeAdminUser: serverUrl + '/web/businessAdmin/changeAdminUser',//修改企业的账户信息(企业管理员)
    checkDuplicate: serverUrl + '/web/businessAdmin/checkDuplicate',//查询用户名是否已被使用
    businessChangeState: serverUrl + '/web/user/changeState',//批量更新企业状态
    carAdd: serverUrl + '/web/car/carAdd',//新增车辆
    carUpdate: serverUrl + '/web/car/update',//修改车辆
    carGetAll: serverUrl + '/web/car/carList',//获取所有车辆信息
    getCarsByBusinessId: serverUrl + '/web/car/getCarsByBusinessId',//获取所有车辆信息(超级管理员)
    getDriversByCarId: serverUrl + '/web/car/getDriversByCarId',//获取车辆下驾驶员信息
    carChangeState: serverUrl + '/web/car/changeState',//批量更新车辆状态
    audioAdd: serverUrl + '/web/trainAudio/add',//添加听力
    audioUpdate: serverUrl + '/web/trainAudio/update',//更新听力
    audioAi: serverUrl + '/web/trainAudio/audioAi',//文字转听力
    audioGetAll: serverUrl + '/web/trainAudio/getList',//获取所有听力
    audioDelete: serverUrl + '/web/trainAudio/delete',//删除听力
    audioGetAllByType: serverUrl + '/web/trainAllocation/getItems',//通过类型获取所有教材
    allocationAdd: serverUrl + '/web/trainAllocation/add',//新增培训
    allocationUpdate: serverUrl + '/web/trainAllocation/update',//更新培训
    allocationDelete: serverUrl + '/web/trainAllocation/delete',//删除培训
    allocationGetAll: serverUrl + '/web/trainAllocation/getList',//获取所有培训
    allocationGetDetail: serverUrl + '/web/trainAllocation/getDetail',//获取培训所选教材
    questionAdd: serverUrl + '/web/question/add',//新增问题
    questionUpdate: serverUrl + '/web/question/update',//更新问题
    questionGetAll: serverUrl + '/web/question/getList',//获取所有问题
    questionOptionGetAll: serverUrl + '/web/question/getQuestionOptions',//获取问题所有选项
    questionDelete: serverUrl + '/web/question/delete',//删除问题
    quizAdd: serverUrl + '/web/quiz/addQuiz',//新增考试
    quizUpdate: serverUrl + '/web/quiz/updateQuiz',//更新考试
    quizDelete: serverUrl + '/web/quiz/deleteQuiz',//删除考试
    quizGetAll: serverUrl + '/web/quiz/getList',//获取所有考试
    quizGetDetail: serverUrl + '/web/quiz/getQuestions',//获取考试所有题目
    driverGetAll: serverUrl + '/web/driver/getDriverList',//获取该公司下所有驾驶员
    driverChangeState: serverUrl + '/web/driver/changeState',//修改驾驶员状态
    driverUpdate: serverUrl + '/web/driver/updateDriverInfo',//更新驾驶员信息
    conferenceAdd: serverUrl + '/web/conferenceAdmin/addConference',//新增会议
    conferenceAdd: serverUrl + '/web/conferenceAdmin/addConference',//新增会议
    conferenceUpdate: serverUrl + '/web/conferenceAdmin/updateConference',//修改会议
    deleteConference: serverUrl + '/web/conferenceAdmin/deleteConference',//删除会议
    conferenceGetAll: serverUrl + '/web/conferenceAdmin/getConferenceList',//获取会议列表
    morningConferenceGetAll: serverUrl + '/web/conferenceAdmin/getMorningConferenceList',//获取会议列表
    conferenceGetDetail: serverUrl + '/web/conferenceAdmin/getConferenceDetail',//获取会议详情
    getAllTrainAllocationBusiness: serverUrl + '/web/trainAllocation/getAllBusiness',//根据培训id获取所有公司情况
    getAllQuizBusiness: serverUrl + '/web/quiz/getBusinessListAndCompletion',//根据考试id获取所有公司情况
    getCompletionByTrainIdAndBusinessId: serverUrl + '/web/trainAllocation/getCompletionByTrainIdAndBusinessId',//根据培训id和公司id获取完成情况
    getCompletionByQuizIdAndBusinessId: serverUrl + '/web/quiz/getCompletionByQuizIdAndBusinessId',//根据考试id和公司id获取完成情况
    getConferenceBusinessListByConferenceAdminId: serverUrl + '/web/conferenceAdmin/getConferenceBusinessListByConferenceAdminId',//根据主会议id获取所有公司会议
    getConferenceBusinessDetail: serverUrl + '/web/conferenceAdmin/getConferenceBusinessDetail',//根据公司会议id获取完成情况
    getCargoTransList: serverUrl + '/web/cargoTrans/getCargoTransList',//货车运行获取
    getCargoTransDetail: serverUrl + '/web/cargoTrans/getCargoTransDetail',//获取单条运行记录详细
    getInspectionList: serverUrl + '/web/inspection/getInspectionList',//获取某辆车的临检记录
    getInspectionById: serverUrl + '/web/inspection/getInspectionById',//根据id获取临检详细信息
    getCarMaintainList: serverUrl + '/web/vehicleMaintain/getCarMaintainList',//获取车辆下的维护记录
    getMaintainDetail: serverUrl + '/web/vehicleMaintain/getMaintainDetail',//根据id获取维护详细信息
    D5: serverUrl + '/web/taizhang/d5',//生成D5道路货物运输车辆“三检”表
    huge: serverUrl + '/web/taizhang/huge',//生成交通警察监管记录
    operateGps: serverUrl + '/web/taizhang/operateGps',//生成车辆GPS运行基本情况抽查表
    huoyun: serverUrl + '/web/taizhang/huoyun',//生成货运车辆临检表
    importVehicle: serverUrl + '/web/taizhang/importVehicle',//生成重点车辆运输企业道路交通安全承诺书
    safeOperateConference: serverUrl + '/web/taizhang/safeOperateConference?morning1=0',//生成安全生产会议
    morningConference: serverUrl + '/web/taizhang/safeOperateConference?morning1=1',//生成安全生产会议
    Trafficsafety: serverUrl + '/web/taizhang/Trafficsafety',//生成交通安全宣传教育培训记录表
    maintain: serverUrl + '/web/taizhang/maintain',//生成车辆维护和修理登记表
    hiddenTrouble: serverUrl + '/web/taizhang/hiddenTrouble',//生成车辆维护和修理登记表
    downLoadingLedger: serverUrl + '/web/taizhang/downLoading?url=',//下载台账
    getTaiZhangState: serverUrl + '/web/taizhang/getTaiZhangState',//获取台账生成状态
    getStatisticsByPage: serverUrl + '/web/statistics/getStatisticsByPage',//获取网页大台账
    getLedgerEndTime: serverUrl + '/web/taizhang/endTime',//获取公司截止日期
    getcarDriverStatistics: serverUrl + '/web/statistics/carDriverStatistics',//获取驾驶员车辆情况总览
    getshowDriverStatistics: serverUrl + '/web/statistics/showDriverStatistics',//获取驾驶员情况总览
    getshowVehicleStatistics: serverUrl + '/web/statistics/showVehicleStatistics',//获取驾驶员车辆情况总览
    getshowTrainStatistics: serverUrl + '/web/statistics/showTrainStatistics',//获取驾驶员培训人数总览
    getshowExamStatistics: serverUrl + '/web/statistics/showExamStatistics',//获取驾驶员考试人数总览
    getshowConferenceStatistics: serverUrl + '/web/statistics/showConferenceStatistics',//获取驾驶员与会人数总览
    getshowInspectionStatistics: serverUrl + '/web/statistics/showInspectionStatistics',//获取参与临检的车辆数量总览
    getshowMaintainStatistics: serverUrl + '/web/statistics/showMaintainStatistics',//获取参与维护的车辆数量总览
    getshowCargoStatistics: serverUrl + '/web/statistics/showCargoStatistics',//获取参与运行日志的车辆数量总览
    getInspectionCarListByTime: serverUrl + '/weChat/inspection/getInspectionCarListByTime',//获取临检总览
    getInspectionById1: serverUrl + '/weChat/inspection/getInspectionById',//获取临检详情总览
    getexamStatistics: serverUrl + '/web/statistics/examStatistics',//获取考试详情总览
    gettrainStatistics: serverUrl + '/web/statistics/trainStatistics',//获取培训详情总览
    getMaintainCarListByTime: serverUrl + '/weChat/vehicleMaintain/getMaintainCarListByTime',//获取维护总览
    getItem: serverUrl + '/weChat/vehicleMaintain/getItem',//获取维护项目总览
    getconferenceStatistics: serverUrl + '/web/statistics/conferenceStatistics',//获取安全会议总览
    getCargoTransCarListByTime: serverUrl + '/weChat/cargoTrans/getCargoTransCarListByTime ',//获取行车日志总览
    getCargoTransDetail1: serverUrl + '/weChat/cargoTrans/getCargoTransDetail ',//获取行车日志总览
    getDriverListByBusinessId: serverUrl + '/web/statistics/getDriverListByBusinessId ',//获取驾驶员信息总览
    getDrivingInformationById: serverUrl + '/web/statistics/getDrivingInformationById ',//获取驾驶员驾驶信息总览
    getQualificationInformationById: serverUrl + '/web/statistics/getQualificationInformationById ',//获取驾驶员从业信息总览
    getRecordInformationById: serverUrl + '/web/statistics/getRecordInformationById',//获取驾驶员记录信息总览
    getCarListByBusinessId: serverUrl + '/web/statistics/getCarListByBusinessId',//获取车辆总览
    getCarBaseById: serverUrl + '/web/statistics/getCarBaseById',//获取车辆详情总览
    getCarBusinessById: serverUrl + '/web/statistics/getCarBusinessById',//获取业主信息总览
    getCarGuaById: serverUrl + '/web/statistics/getCarGuaById',//获取挂载车辆总览
    vehicle: serverUrl + '/web/select/vehicle',//获取查找表车辆总览
    driver: serverUrl + '/web/select/driver ',//获取查找表驾驶员总览
    safe: serverUrl + '/web/select/safe',//获取查找表安全员总览
    charge: serverUrl + '/web/select/charge',//获取查找表责任人总览
    business: serverUrl + '/web/select/business',//获取查找表公司总览
    getAdminType2: serverUrl + '/web/getAdminType2',//获取登录用户类型
    getAllPolice: serverUrl + '/web/gov/getAllPolice',//显示全部警察管理员
    updatePolice: serverUrl + '/web/gov/updatePolice',//更新警察管理员的信息
    addPolice: serverUrl + '/web/gov/addPolice',//新增警察管理员
    changeState: serverUrl + '/web/gov/changeState',//修改警察管理员的状态
    changePassword2: serverUrl + '/web/gov/changePassword',//修改警察密码(超级管理员)
    getStatisticsByPage2: serverUrl + '/web/statistics/getStatisticsByPage2',//获取网页大台账
    businessSelect: serverUrl + '/web/select/businessSelect',//公司查询
    vehicleSelect: serverUrl + '/web/select/vehicleSelect',//车辆查询
    driverSelect: serverUrl + '/web/select/driverSelect',//驾驶员查询
    chargeSelect: serverUrl + '/web/select/chargeSelect',//负责人查询
    safeSelect: serverUrl + '/web/select/safeSelect',//安全员查询
    selectStatisticsByPage2: serverUrl + '/web/statistics/selectStatisticsByPage2',//其他查询
    selectPolice: serverUrl + '/web/gov/selectPolice',//警察查询
    sendMessage: serverUrl + '/web/select/sendMessage',//发送消息
    login2: serverUrl + '/web/login2',//登陆接口
    checkBusinessIsTryOut: serverUrl + '/web/taizhang/checkBusinessIsTryOut',//检查台账是否需要缴费
    importantCar: serverUrl + '/web/taizhang/importantCar',//重点车辆
    importantDriver: serverUrl + '/web/taizhang/importantDriver',//重点驾驶员
    B2: serverUrl + '/web/taizhang/driverInformation',//B2
    C: serverUrl + '/web/taizhang/carInformation',//C
    D1_2: serverUrl + '/web/taizhang/D1_2',//D1_2
    D3: serverUrl + '/web/taizhang/D3',//D1_2
    D6_2: serverUrl + '/web/taizhang/D6_2',//D6_2
    D14: serverUrl + '/web/taizhang/D14',//D14
    changeDriver: serverUrl + '/web/car/changeDriver',//换车
    importantCarOne: serverUrl + '/web/taizhang/importantCarOne',//重点车辆表（一车一表）
    quiz: serverUrl + '/web/taizhang/quiz',//考试
};
