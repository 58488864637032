import React, { Component } from  'react'
import { Route } from 'react-router-dom'
import './index.css'
import '../static/css/index.css'
import loadable from "../static/util/loadbale";
import LeftBar from '../static/component/leftBar'
import Header from '../static/component/header'

const Home = loadable(()=>import('./home'));
const Company = loadable(()=>import('./company'));
const Car = loadable(()=>import('./car'));
const Ledger = loadable(()=>import('./ledger'));
const Ledgerjiaojing = loadable(()=>import('./ledgerjiaojing'));
const Ledger2 = loadable(()=>import('./ledger2'));
const Driver = loadable(()=>import('./driver'));
const TrainAllocation = loadable(()=>import('./trainAllocation'));
const TrainAudio = loadable(()=>import('./trainAudio'));
const TrainQuestion = loadable(()=>import('./trainQuestion'));
const TrainQuiz = loadable(()=>import('./trainQuiz'));
const TrainConference = loadable(()=>import('./trainConference'));
const MorningConference = loadable(()=>import('./morningConference'));
const CommonCompany = loadable(()=>import('../static/component/commonCompany'));
const commonCar = loadable(()=>import('./chooseCar'));
const AllocationSituation = loadable(()=>import('./allocationSituation'));
const QuizSituation = loadable(()=>import('./quizSituation'));
const AllConference = loadable(()=>import('./allConference'));
const ConferenceSituation = loadable(()=>import('./conferenceSituation'));
const TransSituation = loadable(()=>import('./transSituation'));
const InspectionSituation = loadable(()=>import('./inspectionSituation'));
const MaintainSituation = loadable(()=>import('./maintainSituation'));
const SituationTable = loadable(()=>import('./table'));

class RightPage extends Component{
    state = {
    };

    render() {
        return(
            <div>
                <Header/>
                <LeftBar/>
                <div className='rightBodyUnfold'>
                    <Route path="/sys/home" component={Home}/>
                    <Route path="/sys/basic/business" component={Company}/>
                    <Route path="/sys/basic/car" component={Car} />
                    <Route path="/sys/basic/driver"  component={Driver} />
                    <Route path="/sys/basic/ledger"  component={Ledger} />
                    <Route path="/sys/basic/ledgerjiaojing"  component={Ledgerjiaojing} />
                    <Route path="/sys/basic/ledger2"  component={Ledger2} />
                    <Route path="/sys/train/allocation"  component={TrainAllocation} />
                    <Route path="/sys/train/audio"  component={TrainAudio} />
                    <Route path="/sys/train/question"  component={TrainQuestion} />
                    <Route path="/sys/train/quiz"  component={TrainQuiz} />
                    <Route path="/sys/train/conference"  component={TrainConference} />
                    <Route path="/sys/train/morningConference"  component={MorningConference} />
                    <Route path="/sys/commonCompany/:type/:id" component={CommonCompany} />
                    <Route path="/sys/commonCar/:businessId" component={commonCar} />
                    <Route path="/sys/allocationSituation/:allocationId/:businessId" component={AllocationSituation} />
                    <Route path="/sys/quizSituation/:quizId/:businessId" component={QuizSituation} />
                    <Route path="/sys/allConference/:conferenceAdminId" component={AllConference} />
                    <Route path="/sys/conferenceSituation/:conferenceBusinessId" component={ConferenceSituation} />
                    <Route path="/sys/transSituation/:carId" component={TransSituation} />
                    <Route path="/sys/inspectionSituation/:carId" component={InspectionSituation} />
                    <Route path="/sys/maintainSituation/:carId" component={MaintainSituation} />
                    <Route path="/sys/basic/SituationTable" component={SituationTable} />

                </div>
            </div>
        )
    }
}

export default RightPage
